import { clsx } from "clsx";
import { twMerge } from "tailwind-merge"

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export const formatAmount = (amount, decimals = 2) => {
  if (!amount) return '0';

  // Convert string with potential commas and suffixes to number
  const cleanAmount = amount.toString().replace(/,/g, '');
  let numericAmount;

  if (cleanAmount.endsWith('B')) {
    numericAmount = parseFloat(cleanAmount.slice(0, -1)) * 1_000_000_000;
  } else if (cleanAmount.endsWith('M')) {
    numericAmount = parseFloat(cleanAmount.slice(0, -1)) * 1_000_000;
  } else if (cleanAmount.endsWith('K')) {
    numericAmount = parseFloat(cleanAmount.slice(0, -1)) * 1_000;
  } else {
    numericAmount = parseFloat(cleanAmount);
  }

  if (isNaN(numericAmount)) return '0';

  // Now format exactly like the backend
  if (numericAmount >= 1_000_000_000) {
    return `${(numericAmount / 1_000_000_000).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })}B`;
  } else if (numericAmount >= 1_000_000) {
    return `${(numericAmount / 1_000_000).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })}M`;
  } else if (numericAmount >= 1_000) {
    return `${(numericAmount / 1_000).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })}K`;
  } else {
    return numericAmount.toLocaleString(undefined, {
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals
    });
  }
};

export const shortenAddress = (address) => {
  if (!address) return '';
  return `${address.slice(0, 4)}...${address.slice(-4)}`;
};

export const getTransactionColor = (type) => {
  switch (type.toLowerCase()) {
    case 'buy':
      return {
        bg: 'bg-[#00B67A]',
        text: 'text-[#00B67A]',
      };
    case 'sell':
      return {
        bg: 'bg-[#FF7F7F]',
        text: 'text-[#FF7F7F]',
      };
    default:
      return {
        bg: 'bg-gray-100',
        text: 'text-gray-700',
      };
  }
};
