'use client'
import React, { useEffect, useRef, useState } from 'react'
import { useGLTF, OrthographicCamera, MeshTransmissionMaterial, Lightformer, Environment, Caustics } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'
import { useSpring, animated } from '@react-spring/three'

import { useMemo } from 'react';
import * as THREE from 'three';
import { useMousePosition } from '@/hooks/useMousePosition';


function Background({ nodes }) {
    const meshRef = useRef(null)
    const materialRef = useRef(null)

    const backgroundMaterial = new THREE.ShaderMaterial({
        uniforms: {
            uTime: { value: 0 },
            uColor1: { value: new THREE.Color(0xff00ff) }, // Starting color (Pink)
            uColor2: { value: new THREE.Color(0x000000) }, // Ending color (Black)
            uNoiseScale: { value: 5.0 },
            uNoiseStrength: { value: 0.1 },
        },
        vertexShader: `
        varying vec2 vUv;
        void main() {
          vUv = uv;
          gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
      `,
        fragmentShader: `
        varying vec2 vUv;
        uniform vec3 uColor1;
        uniform vec3 uColor2;
        uniform float uTime;
        uniform float uNoiseScale;
        uniform float uNoiseStrength;

        // Simple noise function
        float noise(vec2 st) {
            return fract(sin(dot(st.xy, vec2(12.9898,78.233))) * 43758.5453123);
        }

        void main() {
          float gradient = smoothstep(0.0, 1.0, vUv.y);
          vec3 baseColor = mix(uColor1, uColor2, gradient);
          
          // Animated noise
          vec2 noiseCoord = vUv * uNoiseScale + uTime * 0.1;
          float noiseValue = noise(noiseCoord) * uNoiseStrength;
          
          vec3 finalColor = baseColor + vec3(noiseValue);
          gl_FragColor = vec4(finalColor, 1.0);
        }
      `,
    });

    useFrame((state) => {
        if (materialRef.current) {
            materialRef.current.uniforms.uTime.value = state.clock.elapsedTime;
        }
    });

    return (
        <mesh
            ref={meshRef}
            name="Background_Circle"
            castShadow
            geometry={nodes.Background_Circle.geometry}
            material={backgroundMaterial}
            position={[299, -300.935, -1202.79]}
            scale={2.62}
        >
            <shaderMaterial ref={materialRef} attach="material" args={[backgroundMaterial]} />
        </mesh>
    );
}

function Heads({ nodes }) {
    const { mousePosition, isStopped } = useMousePosition()
    const headRefs = useRef([])
    const hetHeadRefs = useRef([])
    const eyeRefs = useRef(Array(40).fill(null)) // Initialize with enough space for all eyes
    const [isIdle, setIsIdle] = useState(false)
    const [idleTimer, setIdleTimer] = useState(null)
    const [animationState, setAnimationState] = useState('default')
    const [isLookingLeft, setIsLookingLeft] = useState(false);

    const meshRef = useRef(null)

    const targetRotation = new THREE.Euler(0.5, 0, 0)

    const laptopPosition = [35, -690, 900]
    useEffect(() => {
        headRefs.current.forEach((group) => {
            if (group) {
                const headPosition = [
                    group.position.x,
                    group.position.y,
                    group.position.z
                ];
                const initialRotation = calculateTargetRotation(headPosition, laptopPosition);
                group.rotation.x = initialRotation.x;
                group.rotation.y = initialRotation.y;
            }
        });
    }, []);

    useEffect(() => {
        // Set up random intervals to look left
        const randomInterval = setInterval(() => {
            setIsLookingLeft(true);
            setTimeout(() => {
                setIsLookingLeft(false);
            }, 2000); // Look left for 2 seconds
        }, Math.random() * 10000 + 5000); // Random interval between 5-15 seconds

        return () => clearInterval(randomInterval);
    }, []);

    useEffect(() => {
        if (isStopped) {
            const timer = setTimeout(() => {
                setIsIdle(true)
            }, 2000)
            setIdleTimer(timer)
        } else {
            clearTimeout(idleTimer)
            setIsIdle(false)
            setAnimationState('default')
        }

        return () => clearTimeout(idleTimer)
    }, [isStopped])


    const calculateTargetRotation = (headPosition, targetPosition) => {
        const headVector = new THREE.Vector3(...headPosition);
        const targetVector = new THREE.Vector3(...targetPosition);
        const direction = new THREE.Vector3().subVectors(targetVector, headVector).normalize();

        const targetRotation = new THREE.Euler();
        targetRotation.y = Math.atan2(direction.x, direction.z);
        targetRotation.x = Math.atan2(-direction.y, Math.sqrt(direction.x * direction.x + direction.z * direction.z));

        return targetRotation;
    };

    useFrame((state) => {
        const { x, y } = mousePosition
        const windowHalfX = window.innerWidth / 2
        const windowHalfY = window.innerHeight / 2

        headRefs.current.forEach((group, index) => {
            if (group) {
                const headPosition = [
                    group.position.x,
                    group.position.y,
                    group.position.z
                ]

                let targetRotation

                if (isIdle) {
                    const time = state.clock.elapsedTime
                    const animationPhase = (time % 10) // 5 second cycle

                    if (animationPhase < 3) { // First 3 seconds for animation
                        switch (animationState) {
                            case 'lookAround':
                                targetRotation = new THREE.Euler(
                                    Math.sin(time * 0.5) * 0.5,
                                    Math.cos(time * 0.5) * 0.5,
                                    0
                                )
                                break
                            case 'nod':
                                targetRotation = new THREE.Euler(
                                    Math.sin(time * 1.5) * 0.1,
                                    0,
                                    0
                                )
                                break
                            case 'shake':
                                targetRotation = new THREE.Euler(
                                    Math.sin(time * 1.5) * 0.1,
                                    Math.cos(time * 1.5) * 0.1,
                                    0
                                )
                                break
                            default:
                                targetRotation = calculateTargetRotation(headPosition, laptopPosition)
                        }
                    } else { // Last 2 seconds for transitioning back to laptop
                        targetRotation = calculateTargetRotation(headPosition, laptopPosition)
                    }

                    // Adjust transition speed based on whether we're returning to laptop position
                    const transitionSpeed = animationPhase < 2 ? 0.02 : 0.1
                    group.rotation.x = THREE.MathUtils.lerp(group.rotation.x, targetRotation.x, transitionSpeed)
                    group.rotation.y = THREE.MathUtils.lerp(group.rotation.y, targetRotation.y, transitionSpeed)

                    // Change animation every 5 seconds with a smooth transition
                    if (time % 10 < 0.1) {
                        setAnimationState(prev => prev === 'lookAround' ? 'nod' : 'lookAround')
                    } else if (time % 5 < 0.2) {
                        setAnimationState(prev => prev === 'nod' ? 'shake' : 'nod')
                    } else if (time % 5 < 0.3) {
                        setAnimationState(prev => prev === 'shake' ? 'lookAround' : 'shake')
                    }
                } else if (isStopped) {
                    targetRotation = calculateTargetRotation(headPosition, laptopPosition)
                    group.rotation.x = THREE.MathUtils.lerp(group.rotation.x, targetRotation.x, 0.05)
                    group.rotation.y = THREE.MathUtils.lerp(group.rotation.y, targetRotation.y, 0.05)
                } else {
                    const mouseX = x - windowHalfX
                    const mouseY = y - windowHalfY
                    const mouseZ = 900
                    targetRotation = calculateTargetRotation(headPosition, [mouseX, -mouseY, mouseZ])
                    group.rotation.x = THREE.MathUtils.lerp(group.rotation.x, targetRotation.x, 0.1)
                    group.rotation.y = THREE.MathUtils.lerp(group.rotation.y, targetRotation.y, 0.1)
                }

                // Rest of the eye animation code remains the same

                const leftEye = eyeRefs.current[index * 2]
                const rightEye = eyeRefs.current[index * 2 + 1]
                if (leftEye && rightEye) {
                    const blinkFrequency = 0.005
                    const isBlinking = Math.random() < blinkFrequency

                    if (isBlinking) {
                        leftEye.scale.y = THREE.MathUtils.lerp(leftEye.scale.y, 0.1, 0.2)
                        rightEye.scale.y = THREE.MathUtils.lerp(rightEye.scale.y, 0.1, 0.2)
                        
                    } else {
                        leftEye.scale.y = THREE.MathUtils.lerp(leftEye.scale.y, 1, 0.1)
                        rightEye.scale.y = THREE.MathUtils.lerp(rightEye.scale.y, 1, 0.1)
                    }
                }
            }
        })
        hetHeadRefs.current.forEach((group) => {
            if (group) {
                // Default straight position
                const defaultRotation = { x: 0, y: 0 };
                // Left-looking rotation
                const leftRotation = { x: 0, y: 0.5 };
                
                // Interpolate between current rotation and target rotation
                const targetRotation = isLookingLeft ? leftRotation : defaultRotation;
                
                group.rotation.x = THREE.MathUtils.lerp(group.rotation.x, targetRotation.x, 0.05);
                group.rotation.y = THREE.MathUtils.lerp(group.rotation.y, targetRotation.y, 0.05);
            }
        })
    })


    function createHeadMaterial(color) {
        return new THREE.MeshPhysicalMaterial({
            color: new THREE.Color(color),
            metalness: 0.4,
            roughness: 2.5,
            clearcoat: 10,
            clearcoatRoughness: 0.8,
            ior: 1.5,
            thickness: 0.5,
            transmission: 0.4,
            specularIntensity: 3,
            specularColor: new THREE.Color(0xffffff),
            opacity: 1,
            side: THREE.FrontSide,
            transparent: true,
            envMapIntensity: 1.5,
            sheen: 0.25,
            sheenRoughness: 1.25,
            sheenColor: new THREE.Color(color).multiplyScalar(0.5),
            attenuationDistance: 0.5,
            attenuationColor: new THREE.Color(color).multiplyScalar(0.5),

        })
    }

    const eyeBrowMaterial = () => {
        return new THREE.MeshStandardMaterial({
            color: black,
            metalness: 0.25,
            roughness: 0.5,
        });
    };

    const eyeMaterial = () => {
        return new THREE.MeshStandardMaterial({
            color: black,
            metalness: 0.1,
            roughness: 0,
        });
    };

    const eyeWhiteMaterial = () => {
        return new THREE.MeshStandardMaterial({
            color: white,
            metalness: 0.1,
            roughness: 0,
        });
    };

    const headColor1 = '#fcd1a2';
    const headColor2 = '#FFADEC';
    const headColor3 = '#865A5A';
    const headColor4 = '#7FEF80';
    const headColor5 = '#5E8FD6';
    const headColor6 = '#C79867';
    const headColor7 = '#A77BFB';
    const headColor8 = '#F35672';
    const headColor9 = '#A5A5A5';
    const headColor10 = '#FB885D';
    const headColor11 = '#F35672';
    const headColor12 = '#FDD863';
    const white = '#FFFFFF';
    const black = '#2E2E2E';
    const purple = '#A5A5A5';

    return (
        <group name="Heads" position={[298.261, 470.56, -562.573]}>
            <group name="Group_4_Instance_14" position={[-610.349, -40.703, -32.919]} ref={(el) => (headRefs.current[0] = el)}>
                {/* <mesh
                    name="Rectangle_1"
                    castShadow
                    receiveShadow
                    geometry={nodes.Rectangle_1.geometry}
                    material={eyeWhiteMaterial()}
                    position={[-0.433, 50.802, -25.708]}
                    rotation={[-1.868, 0, Math.PI / 2]}
                />
                <mesh
                    name="Cone"
                    castShadow
                    receiveShadow
                    geometry={nodes.Cone.geometry}
                    material={createHeadMaterial(headColor10)}
                    position={[-1.441, 155.233, -58.013]}
                    rotation={[-0.3, 0, 0]}
                /> */}
                <mesh
                    name="Shape_26"
                    castShadow
                    receiveShadow
                    geometry={nodes.Shape_26.geometry}
                    material={eyeBrowMaterial()}
                    position={[24.087, 60.502, 65.424]}
                    rotation={[Math.PI, -0.553, -3.072]}
                    scale={[-1, 1, 1]}
                />
                <mesh
                    name="Shape_27"
                    castShadow
                    receiveShadow
                    geometry={nodes.Shape_27.geometry}
                    material={eyeBrowMaterial()}
                    position={[-54.633, 58.118, 54.241]}
                    rotation={[0, -0.422, 0.094]}
                />
                <mesh
                    name="Sphere_6_25"
                    castShadow
                    receiveShadow
                    ref={(el) => (eyeRefs.current[0] = el)}
                    geometry={nodes.Sphere_6_25.geometry}
                    material={eyeMaterial()}
                    position={[-38.215, 21.412, 74.542]}
                    rotation={[-0.185, -0.344, -0.063]}
                    scale={[1.15, 1.15, 0.324]}
                />
                <mesh
                    name="Sphere_5_12"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_5_12.geometry}
                    material={eyeWhiteMaterial()}
                    position={[-40.373, 21.412, 67.241]}
                    rotation={[-0.185, -0.344, -0.063]}
                    scale={[1.65, 1.65, 0.466]}
                />
                <mesh
                    name="Sphere_6_26"
                    castShadow
                    receiveShadow
                    ref={(el) => (eyeRefs.current[1] = el)} // Right eye
                    geometry={nodes.Sphere_6_26.geometry}
                    material={eyeMaterial()}
                    position={[38.146, 21.4, 74.431]}
                    rotation={[-0.168, 0.344, 0.063]}
                    scale={[1.15, 1.15, 0.324]}
                />
                <mesh
                    name="Sphere_3_11"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_3_11.geometry}
                    material={eyeWhiteMaterial()}
                    position={[40, 21.412, 67.241]}
                    rotation={[-0.168, 0.344, 0.063]}
                    scale={[1.65, 1.65, 0.466]}
                />
                <mesh
                    name="Cylinder_2_13"
                    castShadow
                    receiveShadow
                    geometry={nodes.Cylinder_2_13.geometry}
                    material={createHeadMaterial(headColor1)}

                    position={[-0.433, 4.206, -5.505]}
                    rotation={[0, 0, Math.PI / 2]}
                    scale={[0.969, 0.924, 0.557]}
                />
                <mesh
                    name="Cylinder_13"
                    castShadow
                    receiveShadow
                    geometry={nodes.Cylinder_13.geometry}
                    material={createHeadMaterial(headColor1)}

                    position={[0, 12.463, 64.967]}
                    rotation={[2.792, 0, 0]}
                    scale={[0.969, 1.147, 1.147]}
                />
                <mesh
                    name="Sphere_13"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_13.geometry}
                    material={createHeadMaterial(headColor1)}
                    position={[0, 0, -12.338]}
                />
            </group>

            <group name="Group_4_Instance_9" position={[-713.222, -290.717, 172.791]} ref={el => (hetHeadRefs.current[1] = el)}>
                <mesh
                    name="Shape_2"
                    castShadow
                    receiveShadow
                    geometry={nodes.Shape_2.geometry}
                    material={eyeBrowMaterial()}
                    position={[12.087, -30.502, 65.424]}
                    rotation={[Math.PI, -0.553, -3.072]}
                // scale={[-1, 1, 1]}
                />
                <mesh
                    name="Shape_2"
                    castShadow
                    receiveShadow
                    geometry={nodes.Shape_2.geometry}
                    material={eyeBrowMaterial()}
                    position={[30.087, -30.502, 65.424]}
                    rotation={[Math.PI, -0.553, -3.072]}
                // scale={[-1, 1, 1]}
                />
                <mesh
                    name="Shape_3"
                    castShadow
                    receiveShadow
                    geometry={nodes.Shape_3.geometry}
                    material={eyeBrowMaterial()}
                    position={[55.087, 55.502, 65.424]}
                    rotation={[Math.PI, -0.553, -3.072]}
                />
                <mesh
                    name="Shape_3"
                    castShadow
                    receiveShadow
                    geometry={nodes.Shape_3.geometry}
                    material={eyeBrowMaterial()}
                    position={[-54.633, 58.118, 54.241]}
                    rotation={[0, -0.422, 0.094]}
                />
                <mesh
                    name="Sphere_6_2"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_6_2.geometry}
                    material={eyeMaterial()}
                    ref={(el) => (eyeRefs.current[2] = el)}
                    position={[-38.215, 21.412, 74.542]}
                    rotation={[-0.185, -0.344, 3.078]}
                    scale={[1.15, 1.15, 0.324]}
                />
                <mesh
                    name="Sphere_5_1"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_5_1.geometry}
                    material={eyeWhiteMaterial()}
                    position={[-40.373, 21.412, 67.241]}
                    rotation={[-0.185, -0.344, -0.063]}
                    scale={[1.65, 1.65, 0.466]}
                />
                <mesh
                    name="Sphere_6_3"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_6_3.geometry}
                    ref={(el) => (eyeRefs.current[3] = el)} // Right eye
                    material={eyeMaterial()}
                    position={[38.146, 21.4, 74.431]}
                    rotation={[-0.168, 0.344, -3.078]}
                    scale={[1.15, 1.15, 0.324]}
                />
                <mesh
                    name="Sphere_3_1"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_3_1.geometry}
                    material={eyeWhiteMaterial()}
                    position={[40, 21.412, 67.241]}
                    rotation={[-0.168, 0.344, -3.078]}
                    scale={[1.65, 1.65, 0.466]}
                />
                <mesh
                    name="Cylinder_2_1"
                    castShadow
                    receiveShadow
                    geometry={nodes.Cylinder_2_1.geometry}
                    material={createHeadMaterial(headColor9)}
                    position={[-0.433, 4.206, -5.505]}
                    rotation={[0, 0, Math.PI / 2]}
                    scale={[0.969, 0.924, 0.557]}
                />
                <mesh
                    name="Cylinder_1"
                    castShadow
                    receiveShadow
                    geometry={nodes.Cylinder_1.geometry}
                    material={createHeadMaterial(headColor9)}

                    position={[0, 12.463, 64.967]}
                    rotation={[2.792, 0, 0]}
                    scale={[0.969, 1.147, 1.147]}
                />
                <mesh
                    name="Sphere_1"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_1.geometry}
                    material={createHeadMaterial(headColor9)}
                    position={[0, 0, -12.338]}
                />

            </group>
            <group name="Group_4_Instance_8" position={[-391.413, -148.978, 95.863]} ref={(el) => (headRefs.current[2] = el)}>
                <mesh
                    name="Shape_4"
                    castShadow
                    receiveShadow
                    geometry={nodes.Shape_4.geometry}
                    material={nodes.Shape_4.material}
                    position={[24.087, 60.502, 65.424]}
                    rotation={[Math.PI, -0.553, -3.072]}
                    scale={[-1, 1, 1]}
                />
                <mesh
                    name="Shape_5"
                    castShadow
                    receiveShadow
                    geometry={nodes.Shape_5.geometry}
                    material={nodes.Shape_5.material}
                    position={[-54.633, 58.118, 54.241]}
                    rotation={[0, -0.422, 0.094]}
                />
                <mesh
                    name="Sphere_6_4"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_6_4.geometry}
                    material={
                        new THREE.MeshStandardMaterial({
                            color: black,
                            metalness: 0.1,
                            roughness: 0,
                        })
                    }
                    position={[-38.215, 21.412, 74.542]}
                    rotation={[-0.185, -0.344, -0.063]}
                    scale={[0.72, 0.72, 0.32]}
                />
                <mesh
                    name="Sphere_6_5"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_6_5.geometry}
                    material={
                        new THREE.MeshStandardMaterial({
                            color: black,
                            metalness: 0.1,
                            roughness: 0,
                        })
                    }
                    position={[38.146, 21.4, 74.431]}
                    rotation={[-0.168, 0.344, 0.063]}
                    scale={[0.73, 0.73, 0.21]}
                />
                <mesh
                    name="Cylinder_2_2"
                    castShadow
                    receiveShadow
                    geometry={nodes.Cylinder_2_2.geometry}
                    material={createHeadMaterial(headColor2)}
                    position={[-0.433, 4.206, -5.505]}
                    rotation={[0, 0, Math.PI / 2]}
                    scale={[0.969, 0.924, 0.557]}
                />
                <mesh
                    name="Cylinder_2"
                    castShadow
                    receiveShadow
                    geometry={nodes.Cylinder_2.geometry}
                    material={createHeadMaterial(headColor2)}
                    position={[0, 12.463, 64.967]}
                    rotation={[2.792, 0, 0]}
                    scale={[0.969, 1.147, 1.147]}
                />
                <mesh
                    name="Sphere_2"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_2.geometry}
                    material={createHeadMaterial(headColor2)}
                    position={[0, 0, -12.338]}
                />
            </group>
            <group name="Group_4_Instance_7" position={[-79.837, 74.07, -36.728]} ref={(el) => (headRefs.current[3] = el)}>
                <mesh
                    name="Shape_6"
                    castShadow
                    receiveShadow
                    geometry={nodes.Shape_6.geometry}
                    material={eyeBrowMaterial()}
                    position={[24.087, 60.502, 65.424]}
                    rotation={[Math.PI, -0.553, -3.072]}
                    scale={[-1, 1, 1]}
                />
                <mesh
                    name="Shape_7"
                    castShadow
                    receiveShadow
                    geometry={nodes.Shape_7.geometry}
                    material={eyeBrowMaterial()}
                    position={[-54.633, 58.118, 54.241]}
                    rotation={[0, -0.422, 0.094]}
                />
                <mesh
                    name="Sphere_6_6"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_6_6.geometry}
                    ref={(el) => (eyeRefs.current[4] = el)}

                    material={
                        eyeMaterial()
                    }
                    position={[-38.215, 21.412, 74.542]}
                    rotation={[-0.185, -0.344, -0.063]}
                    scale={[1.15, 1.15, 0.324]}
                />
                <mesh
                    name="Sphere_5_2"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_5_2.geometry}
                    material={
                        eyeWhiteMaterial()
                    }
                    position={[-40.373, 21.412, 67.241]}
                    rotation={[-0.185, -0.344, -0.063]}
                    scale={[1.65, 1.65, 0.466]}
                />
                <mesh
                    name="Sphere_6_7"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_6_7.geometry}
                    ref={(el) => (eyeRefs.current[5] = el)} // Right eye

                    material={
                        eyeMaterial()
                    }
                    position={[38.146, 21.4, 74.431]}
                    rotation={[-0.168, 0.344, 0.063]}
                    scale={[1.15, 1.15, 0.324]}
                />
                <mesh
                    name="Sphere_3_2"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_3_2.geometry}
                    material={
                        eyeWhiteMaterial()
                    }
                    position={[40, 21.412, 67.241]}
                    rotation={[-0.168, 0.344, 0.063]}
                    scale={[1.65, 1.65, 0.466]}
                />
                <mesh
                    name="Cylinder_2_3"
                    castShadow
                    receiveShadow
                    geometry={nodes.Cylinder_2_3.geometry}
                    material={createHeadMaterial(headColor3)}

                    position={[-0.433, 4.206, -5.505]}
                    rotation={[0, 0, Math.PI / 2]}
                    scale={[0.969, 0.924, 0.557]}
                />
                <mesh
                    name="Cylinder_3"
                    castShadow
                    receiveShadow
                    geometry={nodes.Cylinder_3.geometry}
                    material={createHeadMaterial(headColor3)}
                    position={[0, 12.463, 64.967]}
                    rotation={[2.792, 0, 0]}
                    scale={[0.969, 1.147, 1.147]}
                />
                <mesh
                    name="Sphere_3"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_3.geometry}
                    material={createHeadMaterial(headColor3)}

                    position={[0, 0, -12.338]}
                />
            </group>
            <group name="Group_4_Instance_5" position={[279.019, -96.674, 86.966]} ref={(el) => (headRefs.current[4] = el)}>
                <mesh
                    name="Shape_8"
                    castShadow
                    receiveShadow
                    geometry={nodes.Shape_8.geometry}
                    material={eyeBrowMaterial()}
                    position={[24.087, 60.502, 65.424]}
                    rotation={[Math.PI, -0.553, -3.072]}
                    scale={[-1, 1, 1]}
                />
                <mesh
                    name="Shape_9"
                    castShadow
                    receiveShadow
                    geometry={nodes.Shape_9.geometry}
                    material={eyeBrowMaterial()}
                    position={[-54.633, 58.118, 54.241]}
                    rotation={[0, -0.422, 0.094]}
                />
                <mesh
                    name="Sphere_6_8"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_6_8.geometry}
                    ref={(el) => (eyeRefs.current[6] = el)}

                    material={eyeMaterial()}
                    position={[-38.215, 21.412, 74.542]}
                    rotation={[-0.185, -0.344, -0.063]}
                    scale={[1.15, 1.15, 0.324]}
                />
                <mesh
                    name="Sphere_5_3"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_5_3.geometry}
                    material={eyeWhiteMaterial()}
                    position={[-40.373, 21.412, 67.241]}
                    rotation={[-0.185, -0.344, -0.063]}
                    scale={[1.65, 1.65, 0.466]}
                />
                <mesh
                    name="Sphere_6_9"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_6_9.geometry}
                    ref={(el) => (eyeRefs.current[7] = el)} // Right eye

                    material={eyeMaterial()}
                    position={[38.146, 21.4, 74.431]}
                    rotation={[-0.168, 0.344, 0.063]}
                    scale={[1.15, 1.15, 0.324]}
                />
                <mesh
                    name="Sphere_3_3"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_3_3.geometry}
                    material={eyeWhiteMaterial()}
                    position={[40, 21.412, 67.241]}
                    rotation={[-0.168, 0.344, 0.063]}
                    scale={[1.65, 1.65, 0.466]}
                />
                <mesh
                    name="Cylinder_2_4"
                    castShadow
                    receiveShadow
                    geometry={nodes.Cylinder_2_4.geometry}
                    material={createHeadMaterial(headColor4)}

                    position={[-0.433, 4.206, -5.505]}
                    rotation={[0, 0, Math.PI / 2]}
                    scale={[0.969, 0.924, 0.557]}
                />
                <mesh
                    name="Cylinder_4"
                    castShadow
                    receiveShadow
                    geometry={nodes.Cylinder_4.geometry}
                    material={createHeadMaterial(headColor4)}

                    position={[0, 12.463, 64.967]}
                    rotation={[2.792, 0, 0]}
                    scale={[0.969, 1.147, 1.147]}
                />
                <mesh
                    name="Sphere_4"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_4.geometry}
                    material={createHeadMaterial(headColor4)}
                    position={[0, 0, -12.338]}
                />
            </group>
            <group name="Group_4_Instance_4" position={[39.782, -131.965, 233.293]} ref={(el) => (headRefs.current[5] = el)}>
                <mesh
                    name="Shape_10"
                    castShadow
                    receiveShadow
                    geometry={nodes.Shape_10.geometry}
                    material={eyeBrowMaterial()}
                    position={[24.087, 60.502, 65.424]}
                    rotation={[Math.PI, -0.553, -3.072]}
                    scale={[-1, 1, 1]}
                />
                <mesh
                    name="Shape_11"
                    castShadow
                    receiveShadow
                    geometry={nodes.Shape_11.geometry}
                    material={eyeBrowMaterial()}
                    position={[-54.633, 58.118, 54.241]}
                    rotation={[0, -0.422, 0.094]}
                />
                <mesh
                    name="Sphere_6_10"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_6_10.geometry}
                    ref={(el) => (eyeRefs.current[8] = el)}

                    material={eyeMaterial()}
                    position={[-38.215, 21.412, 74.542]}
                    rotation={[-0.185, -0.344, -0.063]}
                    scale={[1.15, 1.15, 0.324]}
                />
                <mesh
                    name="Sphere_5_4"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_5_4.geometry}
                    material={eyeWhiteMaterial()}
                    position={[-40.373, 21.412, 67.241]}
                    rotation={[-0.185, -0.344, -0.063]}
                    scale={[1.65, 1.65, 0.466]}
                />
                <mesh
                    name="Sphere_6_11"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_6_11.geometry}
                    ref={(el) => (eyeRefs.current[9] = el)} // Right eye

                    material={eyeMaterial()}
                    position={[38.146, 21.4, 74.431]}
                    rotation={[-0.168, 0.344, 0.063]}
                    scale={[1.15, 1.15, 0.324]}
                />
                <mesh
                    name="Sphere_3_4"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_3_4.geometry}
                    material={eyeWhiteMaterial()}
                    position={[40, 21.412, 67.241]}
                    rotation={[-0.168, 0.344, 0.063]}
                    scale={[1.65, 1.65, 0.466]}
                />
                <mesh
                    name="Cylinder_2_5"
                    castShadow
                    receiveShadow
                    geometry={nodes.Cylinder_2_5.geometry}
                    material={createHeadMaterial(headColor5)}

                    position={[-0.433, 4.206, -5.505]}
                    rotation={[0, 0, Math.PI / 2]}
                    scale={[0.969, 0.924, 0.557]}
                />
                <mesh
                    name="Cylinder_5"
                    castShadow
                    receiveShadow
                    geometry={nodes.Cylinder_5.geometry}
                    material={createHeadMaterial(headColor5)}

                    position={[0, 12.463, 64.967]}
                    rotation={[2.792, 0, 0]}
                    scale={[0.969, 1.147, 1.147]}
                />
                <mesh
                    name="Sphere_5"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_5.geometry}
                    material={createHeadMaterial(headColor5)}
                    position={[0, 0, -12.338]}
                />
            </group>
            <group name="Group_4_Instance_21" position={[457.603, -244.832, 57.778]} ref={(el) => (headRefs.current[6] = el)}>
                <mesh
                    name="Shape_12"
                    castShadow
                    receiveShadow
                    geometry={nodes.Shape_12.geometry}
                    material={eyeBrowMaterial()}
                    position={[24.087, 60.502, 65.424]}
                    rotation={[Math.PI, -0.553, -3.072]}
                    scale={[-1, 1, 1]}
                />
                <mesh
                    name="Shape_13"
                    castShadow
                    receiveShadow
                    geometry={nodes.Shape_13.geometry}
                    material={eyeBrowMaterial()}
                    position={[-54.633, 58.118, 54.241]}
                    rotation={[0, -0.422, 0.094]}
                />
                <mesh
                    name="Sphere_6_12"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_6_12.geometry}
                    ref={(el) => (eyeRefs.current[10] = el)}

                    material={eyeMaterial()}
                    position={[-38.215, 21.412, 74.542]}
                    rotation={[-0.185, -0.344, -0.063]}
                    scale={[1.15, 1.15, 0.324]}
                />
                <mesh
                    name="Sphere_5_5"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_5_5.geometry}
                    material={eyeWhiteMaterial()}
                    position={[-40.373, 21.412, 67.241]}
                    rotation={[-0.185, -0.344, -0.063]}
                    scale={[1.65, 1.65, 0.466]}
                />
                <mesh
                    name="Sphere_6_13"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_6_13.geometry}
                    ref={(el) => (eyeRefs.current[11] = el)} // Right eye

                    material={eyeMaterial()}
                    position={[38.146, 21.4, 74.431]}
                    rotation={[-0.168, 0.344, 0.063]}
                    scale={[1.15, 1.15, 0.324]}
                />
                <mesh
                    name="Sphere_3_5"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_3_5.geometry}
                    material={eyeWhiteMaterial()}
                    position={[40, 21.412, 67.241]}
                    rotation={[-0.168, 0.344, 0.063]}
                    scale={[1.65, 1.65, 0.466]}
                />
                <mesh
                    name="Cylinder_2_6"
                    castShadow
                    receiveShadow
                    geometry={nodes.Cylinder_2_6.geometry}
                    material={createHeadMaterial(headColor6)}

                    position={[-0.433, 4.206, -5.505]}
                    rotation={[0, 0, Math.PI / 2]}
                    scale={[0.969, 0.924, 0.557]}
                />
                <mesh
                    name="Cylinder_6"
                    castShadow
                    receiveShadow
                    geometry={nodes.Cylinder_6.geometry}
                    material={createHeadMaterial(headColor6)}

                    position={[0, 12.463, 64.967]}
                    rotation={[2.792, 0, 0]}
                    scale={[0.969, 1.147, 1.147]}
                />
                <mesh
                    name="Sphere_6"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_6.geometry}
                    material={createHeadMaterial(headColor6)}
                    position={[0, 0, -12.338]}
                />
            </group>
            <group name="Group_4_Instance_20" position={[378.966, 33.728, -106.4]} ref={(el) => (headRefs.current[7] = el)}>
                <mesh
                    name="Shape_14"
                    castShadow
                    receiveShadow
                    geometry={nodes.Shape_14.geometry}
                    material={eyeBrowMaterial()}
                    position={[24.087, 60.502, 65.424]}
                    rotation={[Math.PI, -0.553, -3.072]}
                    scale={[-1, 1, 1]}
                />
                <mesh
                    name="Shape_15"
                    castShadow
                    receiveShadow
                    geometry={nodes.Shape_15.geometry}
                    material={eyeBrowMaterial()}
                    position={[-54.633, 58.118, 54.241]}
                    rotation={[0, -0.422, 0.094]}
                />
                <mesh
                    name="Sphere_6_14"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_6_14.geometry}
                    ref={(el) => (eyeRefs.current[12] = el)}

                    material={eyeMaterial()}
                    position={[-38.215, 21.412, 74.542]}
                    rotation={[-0.185, -0.344, -0.063]}
                    scale={[1.15, 1.15, 0.324]}
                />
                <mesh
                    name="Sphere_5_6"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_5_6.geometry}
                    material={eyeWhiteMaterial()}
                    position={[-40.373, 21.412, 67.241]}
                    rotation={[-0.185, -0.344, -0.063]}
                    scale={[1.65, 1.65, 0.466]}
                />
                <mesh
                    name="Sphere_6_15"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_6_15.geometry}
                    ref={(el) => (eyeRefs.current[13] = el)} // Right eye

                    material={eyeMaterial()}
                    position={[38.146, 21.4, 74.431]}
                    rotation={[-0.168, 0.344, 0.063]}
                    scale={[1.15, 1.15, 0.324]}
                />
                <mesh
                    name="Sphere_3_6"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_3_6.geometry}
                    material={eyeWhiteMaterial()}
                    position={[40, 21.412, 67.241]}
                    rotation={[-0.168, 0.344, 0.063]}
                    scale={[1.65, 1.65, 0.466]}
                />
                <mesh
                    name="Cylinder_2_7"
                    castShadow
                    receiveShadow
                    geometry={nodes.Cylinder_2_7.geometry}
                    material={createHeadMaterial(headColor7)}
                    position={[-0.433, 4.206, -5.505]}
                    rotation={[0, 0, Math.PI / 2]}
                    scale={[0.969, 0.924, 0.557]}
                />
                <mesh
                    name="Cylinder_7"
                    castShadow
                    receiveShadow
                    geometry={nodes.Cylinder_7.geometry}
                    material={createHeadMaterial(headColor7)}
                    position={[0, 12.463, 64.967]}
                    rotation={[2.792, 0, 0]}
                    scale={[0.969, 1.147, 1.147]}
                />
                <mesh
                    name="Sphere_7"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_7.geometry}
                    material={createHeadMaterial(headColor7)}
                    position={[0, 0, -12.338]}
                />
            </group>
            <group name="Group_4_Instance_19" position={[-492.739, -421.592, 232.171]} ref={(el) => (headRefs.current[8] = el)}>
                <mesh
                    name="Shape_16"
                    castShadow
                    receiveShadow
                    geometry={nodes.Shape_16.geometry}
                    material={eyeBrowMaterial()}
                    position={[24.087, 60.502, 65.424]}
                    rotation={[Math.PI, -0.553, -3.072]}
                    scale={[-1, 1, 1]}
                />
                <mesh
                    name="Shape_17"
                    castShadow
                    receiveShadow
                    geometry={nodes.Shape_17.geometry}
                    material={eyeBrowMaterial()}
                    position={[-54.633, 58.118, 54.241]}
                    rotation={[0, -0.422, 0.094]}
                />
                <mesh
                    name="Sphere_6_16"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_6_16.geometry}
                    ref={(el) => (eyeRefs.current[14] = el)}

                    material={eyeMaterial()}
                    position={[-38.215, 21.412, 74.542]}
                    rotation={[-0.185, -0.344, -0.063]}
                    scale={[1.15, 1.15, 0.324]}
                />
                <mesh
                    name="Sphere_5_7"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_5_7.geometry}
                    material={eyeWhiteMaterial()}
                    position={[-40.373, 21.412, 67.241]}
                    rotation={[-0.185, -0.344, -0.063]}
                    scale={[1.65, 1.65, 0.466]}
                />
                <mesh
                    name="Sphere_6_17"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_6_17.geometry}
                    material={eyeMaterial()}
                    ref={(el) => (eyeRefs.current[15] = el)} // Right eye

                    position={[38.146, 21.4, 74.431]}
                    rotation={[-0.168, 0.344, 0.063]}
                    scale={[1.15, 1.15, 0.324]}
                />
                <mesh
                    name="Sphere_3_7"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_3_7.geometry}
                    material={eyeWhiteMaterial()}
                    position={[40, 21.412, 67.241]}
                    rotation={[-0.168, 0.344, 0.063]}
                    scale={[1.65, 1.65, 0.466]}
                />
                <mesh
                    name="Cylinder_2_8"
                    castShadow
                    receiveShadow
                    geometry={nodes.Cylinder_2_8.geometry}
                    material={createHeadMaterial(headColor1)}
                    position={[-0.433, 4.206, -5.505]}
                    rotation={[0, 0, Math.PI / 2]}
                    scale={[0.969, 0.924, 0.557]}
                />
                <mesh
                    name="Cylinder_8"
                    castShadow
                    receiveShadow
                    geometry={nodes.Cylinder_8.geometry}
                    material={createHeadMaterial(headColor1)}

                    position={[0, 12.463, 64.967]}
                    rotation={[2.792, 0, 0]}
                    scale={[0.969, 0.774, 1.147]}
                />
                <mesh
                    name="Sphere_8"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_8.geometry}
                    material={createHeadMaterial(headColor1)}
                    position={[0, 0, 0.738]}
                />
            </group>
            <group name="Group_4_Instance_18" position={[-405.473, 109.992, -94.254]} ref={(el) => (headRefs.current[9] = el)}>
                <group
                    name="Group_6"
                    position={[41.495, 22.167, 63.859]}
                    rotation={[-0.266, 0.481, 0.126]}>
                    <mesh
                        name="Rectangle_2"
                        castShadow
                        receiveShadow
                        geometry={nodes.Rectangle_2.geometry}
                        material={eyeBrowMaterial()}
                        rotation={[0, 0, -2.356]}
                    />
                    <mesh
                        name="Rectangle"
                        castShadow
                        receiveShadow
                        geometry={nodes.Rectangle.geometry}
                        material={eyeBrowMaterial()}
                        rotation={[0, 0, -Math.PI / 4]}
                    />
                </group>
                <mesh
                    name="Shape_2"
                    castShadow
                    receiveShadow
                    geometry={nodes.Shape_2.geometry}
                    material={eyeBrowMaterial()}
                    position={[29.437, 60.064, 62.123]}
                    rotation={[Math.PI, -0.553, -3.072]}
                    scale={[-1, 1, 1]}
                />
                <mesh
                    name="Shape_18"
                    castShadow
                    receiveShadow
                    geometry={nodes.Shape_18.geometry}
                    material={eyeBrowMaterial()}
                    position={[24.087, 60.502, 65.424]}
                    rotation={[Math.PI, -0.553, -3.072]}
                    scale={[-1, 1, 1]}
                />
                <mesh
                    name="Shape_19"
                    castShadow
                    receiveShadow
                    geometry={nodes.Shape_19.geometry}
                    material={eyeBrowMaterial()}
                    position={[-54.633, 58.118, 54.241]}
                    rotation={[0, -0.422, 0.094]}
                />
                <mesh
                    name="Sphere_6_18"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_6_18.geometry}
                    ref={(el) => (eyeRefs.current[16] = el)}

                    material={eyeMaterial()}
                    position={[-38.215, 21.412, 74.542]}
                    rotation={[-0.185, -0.344, -0.063]}
                    scale={[1.15, 1.15, 0.324]}
                />
                <mesh
                    name="Sphere_5_8"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_5_8.geometry}
                    material={eyeWhiteMaterial()}
                    position={[-40.373, 21.412, 67.241]}
                    rotation={[-0.185, -0.344, -0.063]}
                    scale={[1.65, 1.65, 0.466]}
                />
                <mesh
                    name="Cylinder_2_9"
                    castShadow
                    receiveShadow
                    geometry={nodes.Cylinder_2_9.geometry}
                    material={createHeadMaterial(headColor1)}

                    position={[-0.433, 4.206, -5.505]}
                    rotation={[0, 0, Math.PI / 2]}
                    scale={[0.969, 0.924, 0.557]}
                />
                <mesh
                    name="Cylinder_9"
                    castShadow
                    receiveShadow
                    geometry={nodes.Cylinder_9.geometry}
                    material={createHeadMaterial(headColor1)}

                    position={[0, 12.463, 64.967]}
                    rotation={[2.792, 0, 0]}
                    scale={[0.969, 1.147, 1.147]}
                />
                <mesh
                    name="Sphere_9"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_9.geometry}
                    material={createHeadMaterial(headColor1)}
                    position={[0, 0, -12.338]}
                />
            </group>
            <group name="Group_4_Instance_17" position={[171.863, 230.287, -246.539]} ref={(el) => (headRefs.current[10] = el)}>
                <mesh
                    name="Shape_20"
                    castShadow
                    receiveShadow
                    geometry={nodes.Shape_20.geometry}
                    material={eyeBrowMaterial()}
                    position={[24.087, 60.502, 65.424]}
                    rotation={[Math.PI, -0.553, -3.072]}
                    scale={[-1, 1, 1]}
                />
                <mesh
                    name="Shape_21"
                    castShadow
                    receiveShadow
                    geometry={nodes.Shape_21.geometry}
                    material={eyeBrowMaterial()}
                    position={[-54.633, 58.118, 54.241]}
                    rotation={[0, -0.422, 0.094]}
                />
                <mesh
                    name="Sphere_6_19"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_6_19.geometry}
                    ref={(el) => (eyeRefs.current[18] = el)}

                    material={eyeMaterial()}
                    position={[-38.215, 21.412, 74.542]}
                    rotation={[-0.185, -0.344, -0.063]}
                    scale={[1.15, 1.15, 0.324]}
                />
                <mesh
                    name="Sphere_5_9"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_5_9.geometry}
                    material={eyeWhiteMaterial()}
                    position={[-40.373, 21.412, 67.241]}
                    rotation={[-0.185, -0.344, -0.063]}
                    scale={[1.65, 1.65, 0.466]}
                />
                <mesh
                    name="Sphere_6_20"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_6_20.geometry}
                    ref={(el) => (eyeRefs.current[19] = el)} // Right eye

                    material={eyeMaterial()}
                    position={[38.146, 21.4, 74.431]}
                    rotation={[-0.168, 0.344, 0.063]}
                    scale={[1.15, 1.15, 0.324]}
                />
                <mesh
                    name="Sphere_3_8"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_3_8.geometry}
                    material={eyeWhiteMaterial()}
                    position={[40, 21.412, 67.241]}
                    rotation={[-0.168, 0.344, 0.063]}
                    scale={[1.65, 1.65, 0.466]}
                />
                <mesh
                    name="Cylinder_2_10"
                    castShadow
                    receiveShadow
                    geometry={nodes.Cylinder_2_10.geometry}
                    material={createHeadMaterial(black)}

                    position={[-0.433, 4.206, -5.505]}
                    rotation={[0, 0, Math.PI / 2]}
                    scale={[0.969, 0.924, 0.557]}
                />
                <mesh
                    name="Cylinder_10"
                    castShadow
                    receiveShadow
                    geometry={nodes.Cylinder_10.geometry}
                    material={createHeadMaterial(black)}

                    position={[0, 12.463, 64.967]}
                    rotation={[2.792, 0, 0]}
                    scale={[0.969, 1.147, 1.147]}
                />
                <mesh
                    name="Sphere_10"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_10.geometry}
                    material={createHeadMaterial(black)}
                    position={[0, 0, -12.338]}
                />
            </group>
            <group name="Group_4_Instance_16" position={[-194.761, 215.437, -151.224]} ref={(el) => (headRefs.current[11] = el)}>
                <mesh
                    name="Shape_22"
                    castShadow
                    receiveShadow
                    geometry={nodes.Shape_22.geometry}
                    material={eyeBrowMaterial()}
                    position={[24.087, 60.502, 65.424]}
                    rotation={[Math.PI, -0.553, -3.072]}
                    scale={[-1, 1, 1]}
                />
                <mesh
                    name="Shape_23"
                    castShadow
                    receiveShadow
                    geometry={nodes.Shape_23.geometry}
                    material={eyeBrowMaterial()}
                    position={[-54.633, 58.118, 54.241]}
                    rotation={[0, -0.422, 0.094]}
                />
                <mesh
                    name="Sphere_6_21"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_6_21.geometry}
                    ref={(el) => (eyeRefs.current[20] = el)}

                    material={eyeMaterial()}
                    position={[-38.215, 21.412, 74.542]}
                    rotation={[-0.185, -0.344, -0.063]}
                    scale={[1.15, 1.15, 0.324]}
                />
                <mesh
                    name="Sphere_5_10"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_5_10.geometry}
                    material={eyeWhiteMaterial()}
                    position={[-40.373, 21.412, 67.241]}
                    rotation={[-0.185, -0.344, -0.063]}
                    scale={[1.65, 1.65, 0.466]}
                />
                <mesh
                    name="Sphere_6_22"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_6_22.geometry}
                    ref={(el) => (eyeRefs.current[21] = el)} // Right eye

                    material={eyeMaterial()}
                    position={[38.146, 21.4, 74.431]}
                    rotation={[-0.168, 0.344, 0.063]}
                    scale={[1.15, 1.15, 0.324]}
                />
                <mesh
                    name="Sphere_3_9"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_3_9.geometry}
                    material={eyeWhiteMaterial()}
                    position={[40, 21.412, 67.241]}
                    rotation={[-0.168, 0.344, 0.063]}
                    scale={[1.65, 1.65, 0.466]}
                />
                <mesh
                    name="Cylinder_2_11"
                    castShadow
                    receiveShadow
                    geometry={nodes.Cylinder_2_11.geometry}
                    material={createHeadMaterial(headColor10)}

                    position={[-0.433, 4.206, -5.505]}
                    rotation={[0, 0, Math.PI / 2]}
                    scale={[0.969, 0.924, 0.557]}
                />
                <mesh
                    name="Cylinder_11"
                    castShadow
                    receiveShadow
                    geometry={nodes.Cylinder_11.geometry}
                    material={createHeadMaterial(headColor10)}

                    position={[0, 12.463, 64.967]}
                    rotation={[2.792, 0, 0]}
                    scale={[0.969, 1.147, 1.147]}
                />
                <mesh
                    name="Sphere_11"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_11.geometry}
                    material={createHeadMaterial(headColor10)}
                    position={[0, 0, -12.338]}
                />
            </group>
            <group name="Group_4_Instance_15" position={[182.641, 65.786, -87.767]} ref={(el) => (headRefs.current[12] = el)}>
                <mesh
                    name="Shape_24"
                    castShadow
                    receiveShadow
                    geometry={nodes.Shape_24.geometry}
                    material={eyeBrowMaterial()}
                    position={[24.087, 60.502, 65.424]}
                    rotation={[Math.PI, -0.553, -3.072]}
                    scale={[-1, 1, 1]}
                />
                <mesh
                    name="Shape_25"
                    castShadow
                    receiveShadow
                    geometry={nodes.Shape_25.geometry}
                    material={eyeBrowMaterial()}
                    position={[-54.633, 58.118, 54.241]}
                    rotation={[0, -0.422, 0.094]}
                />
                <mesh
                    name="Sphere_6_23"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_6_23.geometry}
                    ref={(el) => (eyeRefs.current[22] = el)}

                    material={eyeMaterial()}
                    position={[-38.215, 21.412, 74.542]}
                    rotation={[-0.185, -0.344, -0.063]}
                    scale={[1.15, 1.15, 0.324]}
                />
                <mesh
                    name="Sphere_5_11"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_5_11.geometry}
                    material={eyeWhiteMaterial()}
                    position={[-40.373, 21.412, 67.241]}
                    rotation={[-0.185, -0.344, -0.063]}
                    scale={[1.65, 1.65, 0.466]}
                />
                <mesh
                    name="Sphere_6_24"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_6_24.geometry}
                    ref={(el) => (eyeRefs.current[23] = el)} // Right eye

                    material={eyeMaterial()}
                    position={[38.146, 21.4, 74.431]}
                    rotation={[-0.168, 0.344, 0.063]}
                    scale={[1.15, 1.15, 0.324]}
                />
                <mesh
                    name="Sphere_3_10"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_3_10.geometry}
                    material={eyeWhiteMaterial()}
                    position={[40, 21.412, 67.241]}
                    rotation={[-0.168, 0.344, 0.063]}
                    scale={[1.65, 1.65, 0.466]}
                />
                <mesh
                    name="Cylinder_2_12"
                    castShadow
                    receiveShadow
                    geometry={nodes.Cylinder_2_12.geometry}
                    material={createHeadMaterial(headColor1)}

                    position={[-0.433, 4.206, -5.505]}
                    rotation={[0, 0, Math.PI / 2]}
                    scale={[0.969, 0.924, 0.557]}
                />
                <mesh
                    name="Cylinder_12"
                    castShadow
                    receiveShadow
                    geometry={nodes.Cylinder_12.geometry}
                    material={createHeadMaterial(headColor1)}

                    position={[0, 12.463, 64.967]}
                    rotation={[2.792, 0, 0]}
                    scale={[0.969, 1.147, 1.147]}
                />
                <mesh
                    name="Sphere_12"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_12.geometry}
                    material={createHeadMaterial(headColor1)}
                    position={[0, 0, -12.338]}
                />
            </group>

            <group name="Group_4_Instance_14" position={[555.251, -40.886, -20.928]} ref={(el) => (headRefs.current[13] = el)}>
                <mesh
                    name="Rectangle_1"
                    castShadow
                    receiveShadow
                    geometry={nodes.Rectangle_1.geometry}
                    material={eyeWhiteMaterial()}
                    position={[-0.433, 50.802, -25.708]}
                    rotation={[-1.868, 0, Math.PI / 2]}
                />
                <mesh
                    name="Cone"
                    castShadow
                    receiveShadow
                    geometry={nodes.Cone.geometry}
                    material={createHeadMaterial(headColor10)}
                    position={[-1.441, 155.233, -58.013]}
                    rotation={[-0.3, 0, 0]}
                />
                <mesh
                    name="Shape_26"
                    castShadow
                    receiveShadow
                    geometry={nodes.Shape_26.geometry}
                    material={eyeBrowMaterial()}
                    position={[24.087, 60.502, 65.424]}
                    rotation={[Math.PI, -0.553, -3.072]}
                    scale={[-1, 1, 1]}
                />
                <mesh
                    name="Shape_27"
                    castShadow
                    receiveShadow
                    geometry={nodes.Shape_27.geometry}
                    material={eyeBrowMaterial()}
                    position={[-54.633, 58.118, 54.241]}
                    rotation={[0, -0.422, 0.094]}
                />
                <mesh
                    name="Sphere_6_25"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_6_25.geometry}
                    ref={(el) => (eyeRefs.current[24] = el)}

                    material={eyeMaterial()}
                    position={[-38.215, 21.412, 74.542]}
                    rotation={[-0.185, -0.344, -0.063]}
                    scale={[1.15, 1.15, 0.324]}
                />
                <mesh
                    name="Sphere_5_12"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_5_12.geometry}
                    material={eyeWhiteMaterial()}
                    position={[-40.373, 21.412, 67.241]}
                    rotation={[-0.185, -0.344, -0.063]}
                    scale={[1.65, 1.65, 0.466]}
                />
                <mesh
                    name="Sphere_6_26"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_6_26.geometry}
                    ref={(el) => (eyeRefs.current[25] = el)} // Right eye

                    material={eyeMaterial()}
                    position={[38.146, 21.4, 74.431]}
                    rotation={[-0.168, 0.344, 0.063]}
                    scale={[1.15, 1.15, 0.324]}
                />
                <mesh
                    name="Sphere_3_11"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_3_11.geometry}
                    material={eyeWhiteMaterial()}
                    position={[40, 21.412, 67.241]}
                    rotation={[-0.168, 0.344, 0.063]}
                    scale={[1.65, 1.65, 0.466]}
                />
                <mesh
                    name="Cylinder_2_13"
                    castShadow
                    receiveShadow
                    geometry={nodes.Cylinder_2_13.geometry}
                    material={createHeadMaterial(headColor1)}

                    position={[-0.433, 4.206, -5.505]}
                    rotation={[0, 0, Math.PI / 2]}
                    scale={[0.969, 0.924, 0.557]}
                />
                <mesh
                    name="Cylinder_13"
                    castShadow
                    receiveShadow
                    geometry={nodes.Cylinder_13.geometry}
                    material={createHeadMaterial(headColor1)}

                    position={[0, 12.463, 64.967]}
                    rotation={[2.792, 0, 0]}
                    scale={[0.969, 1.147, 1.147]}
                />
                <mesh
                    name="Sphere_13"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_13.geometry}
                    material={createHeadMaterial(headColor1)}
                    position={[0, 0, -12.338]}
                />
            </group>

            <group name="Group_4_Instance_13" position={[731.111, -244.832, 64.456]} ref={(el) => (headRefs.current[14] = el)}>
                <mesh
                    name="Sphere_14"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_14.geometry}
                    material={eyeWhiteMaterial()}
                    position={[-37.838, 31.599, 62.575]}
                />
                <mesh
                    name="Sphere_15"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_15.geometry}
                    material={eyeWhiteMaterial()}
                    position={[39.629, 31.599, 62.575]}
                />
                <mesh
                    name="Boolean"
                    castShadow
                    receiveShadow
                    geometry={nodes.Boolean.geometry}
                    material={createHeadMaterial(white)}
                    position={[0, 0, -12.338]}
                />
            </group>

            <group name="Group_4_Instance_3" position={[-188.53, -96.674, 68.103]} ref={(el) => (headRefs.current[15] = el)}>
                <mesh
                    name="Cone_1"
                    castShadow
                    receiveShadow
                    geometry={nodes.Cone_1.geometry}

                    material={eyeMaterial()}
                    position={[-0.433, 6.42, -8.99]}
                    rotation={[-0.989, 0, 0]}
                />
                <mesh
                    name="Sphere_6_27"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_6_27.geometry}
                    ref={(el) => (eyeRefs.current[26] = el)}

                    material={eyeMaterial()}
                    position={[-38.215, 21.412, 74.542]}
                    rotation={[-0.185, -0.344, -0.063]}
                    scale={[1.15, 1.15, 0.324]}
                />
                <mesh
                    name="Sphere_5_13"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_5_13.geometry}
                    material={eyeWhiteMaterial()}
                    position={[-40.373, 21.412, 67.241]}
                    rotation={[-0.185, -0.344, -0.063]}
                    scale={[1.65, 1.65, 0.466]}
                />
                <mesh
                    name="Sphere_6_28"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_6_28.geometry}
                    ref={(el) => (eyeRefs.current[27] = el)} // Right eye
                    material={eyeMaterial()}
                    position={[38.146, 21.4, 74.431]}
                    rotation={[-0.168, 0.344, 0.063]}
                    scale={[1.15, 1.15, 0.324]}
                />
                <mesh
                    name="Sphere_3_12"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_3_12.geometry}
                    material={eyeWhiteMaterial()}
                    position={[40, 21.412, 67.241]}
                    rotation={[-0.168, 0.344, 0.063]}
                    scale={[1.65, 1.65, 0.466]}
                />
                <mesh
                    name="Cylinder_2_14"
                    castShadow
                    receiveShadow
                    geometry={nodes.Cylinder_2_14.geometry}
                    material={createHeadMaterial(headColor1)}

                    position={[-0.433, 4.206, -5.505]}
                    rotation={[0, 0, Math.PI / 2]}
                    scale={[0.969, 0.924, 0.557]}
                />
                <mesh
                    name="Cylinder_14"
                    castShadow
                    receiveShadow
                    geometry={nodes.Cylinder_14.geometry}
                    material={createHeadMaterial(headColor1)}

                    position={[0, 12.463, 64.967]}
                    rotation={[2.792, 0, 0]}
                    scale={[0.969, 1.147, 1.147]}
                />
                <mesh
                    name="Sphere_16"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_16.geometry}
                    material={createHeadMaterial(headColor1)}

                    position={[0, 0, -12.338]}
                />
                <group position={[-0.433, 6.42, -8.99]} rotation={[-0.989, 0, 0]}>
                    <mesh
                        name="mesh_124_instance_1"
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_124_instance_1.geometry}
                        material={createHeadMaterial(headColor11)}
                        position={[0, 0, 83.99]}
                        rotation={[Math.PI / 2, 0, 0]}
                    />
                    <mesh
                        name="mesh_124_instance_2"
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_124_instance_2.geometry}
                        material={createHeadMaterial(headColor11)}
                        position={[0, 34.162, 76.729]}
                        rotation={[1.152, 0, 0]}
                    />
                    <mesh
                        name="mesh_124_instance_3"
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_124_instance_3.geometry}
                        material={createHeadMaterial(headColor11)}
                        position={[0, 62.417, 56.2]}
                        rotation={[0.733, 0, 0]}
                    />
                    <mesh
                        name="mesh_124_instance_4"
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_124_instance_4.geometry}
                        material={createHeadMaterial(headColor11)}
                        position={[0, 79.879, 25.954]}
                        rotation={[Math.PI / 10, 0, 0]}
                    />
                    <mesh
                        name="mesh_124_instance_5"
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_124_instance_5.geometry}
                        material={createHeadMaterial(headColor11)}
                        position={[0, 83.53, -8.779]}
                        rotation={[-0.105, 0, 0]}
                    />
                </group>
            </group>

            <group name="Group_4_Instance_2" position={[502.932, -441.877, 128.268]} ref={(el) => (headRefs.current[16] = el)}>
                <mesh
                    name="Shape_28"
                    castShadow
                    receiveShadow
                    geometry={nodes.Shape_28.geometry}
                    material={eyeBrowMaterial()}
                    position={[24.087, 60.502, 65.424]}
                    rotation={[Math.PI, -0.553, -3.072]}
                    scale={[-1, 1, 1]}
                />
                <mesh
                    name="Shape_29"
                    castShadow
                    receiveShadow
                    geometry={nodes.Shape_29.geometry}
                    material={eyeBrowMaterial()}
                    position={[-54.633, 58.118, 54.241]}
                    rotation={[0, -0.422, 0.094]}
                />
                <mesh
                    name="Sphere_6_29"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_6_29.geometry}
                    ref={(el) => (eyeRefs.current[28] = el)}

                    material={eyeMaterial()}
                    position={[-38.215, 21.412, 74.542]}
                    rotation={[-0.185, -0.344, -0.063]}
                    scale={[1.15, 1.15, 0.324]}
                />
                <mesh
                    name="Sphere_5_14"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_5_14.geometry}
                    material={eyeWhiteMaterial()}
                    position={[-40.373, 21.412, 67.241]}
                    rotation={[-0.185, -0.344, -0.063]}
                    scale={[1.65, 1.65, 0.466]}
                />
                <mesh
                    name="Sphere_6_30"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_6_30.geometry}
                    ref={(el) => (eyeRefs.current[29] = el)}
                    material={eyeMaterial()}
                    position={[38.146, 21.4, 74.431]}
                    rotation={[-0.168, 0.344, 0.063]}
                    scale={[1.15, 1.15, 0.324]}
                />
                <mesh
                    name="Sphere_3_13"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_3_13.geometry}
                    material={eyeWhiteMaterial()}
                    position={[40, 21.412, 67.241]}
                    rotation={[-0.168, 0.344, 0.063]}
                    scale={[1.65, 1.65, 0.466]}
                />
                <mesh
                    name="Cylinder_2_15"
                    castShadow
                    receiveShadow
                    geometry={nodes.Cylinder_2_15.geometry}
                    material={createHeadMaterial(headColor12)}

                    position={[-0.433, 4.206, -5.505]}
                    rotation={[0, 0, Math.PI / 2]}
                    scale={[0.969, 0.924, 0.557]}
                />
                <mesh
                    name="Cylinder_15"
                    castShadow
                    receiveShadow
                    geometry={nodes.Cylinder_15.geometry}
                    material={createHeadMaterial(headColor12)}

                    position={[0, 12.463, 64.967]}
                    rotation={[2.792, 0, 0]}
                    scale={[0.969, 1.147, 1.147]}
                />
                <mesh
                    name="Sphere_17"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_17.geometry}
                    material={createHeadMaterial(headColor12)}

                    position={[0, 0, -12.338]}
                />
            </group>

            <group name="Group_4_Instance_22" position={[-27.691, -337.192, 253.155]} ref={(el) => (headRefs.current[17] = el)}>
                <group name="Group_2" position={[0.601, 25.061, 84.585]}>

                    <mesh
                        name="Ellipse_4"
                        castShadow receiveShadow
                        geometry={nodes.Ellipse_4.geometry}
                        transparent
                        opacity={0.5}

                        position={[-47.999, 0.265, -4.301]}
                    >
                        <meshPhysicalMaterial
                            transparent
                            transmission={0.9}
                            thickness={0.5}
                            roughness={0.1}
                            envMapIntensity={1}
                            clearcoat={1}
                            clearcoatRoughness={0.1}
                            ior={1.5}
                            reflectivity={0.5}
                        />
                    </mesh>

                    <mesh
                        name="Ellipse_2"
                        castShadow
                        receiveShadow
                        geometry={nodes.Ellipse_2.geometry}
                        position={[47.869, 0.265, -4.301]}
                    >
                        <meshPhysicalMaterial
                            transparent
                            transmission={0.9}
                            thickness={0.5}
                            roughness={0.1}
                            envMapIntensity={1}
                            clearcoat={1}
                            clearcoatRoughness={0.1}
                            ior={1.5}
                            reflectivity={0.5}
                        />
                    </mesh>
                    <mesh
                        name="Shape_2_1"
                        castShadow
                        receiveShadow
                        geometry={nodes.Shape_2_1.geometry}
                        material={eyeBrowMaterial()}
                        position={[-17.187, 6.415, 0]}
                    />
                    <mesh
                        name="Ellipse_3"
                        castShadow
                        receiveShadow
                        geometry={nodes.Ellipse_3.geometry}
                        material={eyeMaterial()}
                        position={[47.762, 0, 0]}
                    />
                    <mesh
                        name="Ellipse_2_1"
                        castShadow
                        receiveShadow
                        geometry={nodes.Ellipse_2_1.geometry}
                        material={eyeMaterial()}
                        position={[-48.681, 0, 0]}
                    />
                </group>
                <mesh
                    name="Shape_30"
                    castShadow
                    receiveShadow
                    geometry={nodes.Shape_30.geometry}
                    material={eyeBrowMaterial()}
                    position={[24.087, 60.502, 65.424]}
                    rotation={[Math.PI, -0.553, -3.072]}
                    scale={[-1, 1, 1]}
                />
                <mesh
                    name="Shape_31"
                    castShadow
                    receiveShadow
                    geometry={nodes.Shape_31.geometry}
                    material={eyeBrowMaterial()}
                    position={[-54.633, 58.118, 54.241]}
                    rotation={[0, -0.422, 0.094]}
                />
                <mesh
                    name="Sphere_6_31"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_6_31.geometry}
                    ref={(el) => (eyeRefs.current[30] = el)}
                    material={eyeMaterial()}
                    position={[-38.215, 21.412, 74.542]}
                    rotation={[-0.185, -0.344, -0.063]}
                    scale={[1.15, 1.15, 0.324]}
                />
                <mesh
                    name="Sphere_5_15"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_5_15.geometry}
                    material={eyeWhiteMaterial()}
                    position={[-40.373, 21.412, 67.241]}
                    rotation={[-0.185, -0.344, -0.063]}
                    scale={[1.65, 1.65, 0.466]}
                />
                <mesh
                    name="Sphere_6_32"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_6_32.geometry}
                    ref={(el) => (eyeRefs.current[31] = el)}
                    material={eyeMaterial()}
                    position={[38.146, 21.4, 74.431]}
                    rotation={[-0.168, 0.344, 0.063]}
                    scale={[1.15, 1.15, 0.324]}
                />
                <mesh
                    name="Sphere_3_14"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_3_14.geometry}
                    material={eyeWhiteMaterial()}
                    position={[40, 21.412, 67.241]}
                    rotation={[-0.168, 0.344, 0.063]}
                    scale={[1.65, 1.65, 0.466]}
                />
                <mesh
                    name="Cylinder_2_16"
                    castShadow
                    receiveShadow
                    geometry={nodes.Cylinder_2_16.geometry}
                    material={
                        createHeadMaterial(headColor1)
                    }
                    position={[-0.433, 4.206, -5.505]}
                    rotation={[0, 0, Math.PI / 2]}
                    scale={[0.969, 0.924, 0.557]}
                />
                <mesh
                    name="Cylinder_16"
                    castShadow
                    receiveShadow
                    geometry={nodes.Cylinder_16.geometry}
                    material={
                        createHeadMaterial(headColor1)
                    }
                    position={[0, 12.463, 64.967]}
                    rotation={[2.792, 0, 0]}
                    scale={[0.969, 1.147, 1.147]}
                />
                <mesh
                    name="Sphere_18"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_18.geometry}
                    material={
                        createHeadMaterial(headColor1)
                    }
                    position={[0, 0, -12.338]}
                />
            </group>

            <group name="Group_4_Instance" position={[-290.612, -337.192, 160.725]} ref={(el) => (headRefs.current[18] = el)}>
                <mesh
                    name="Shape_32"
                    castShadow
                    receiveShadow
                    geometry={nodes.Shape_32.geometry}
                    material={eyeBrowMaterial()}
                    position={[24.087, 60.502, 65.424]}
                    rotation={[Math.PI, -0.553, -3.072]}
                    scale={[-1, 1, 1]}
                />
                <mesh
                    name="Shape_33"
                    castShadow
                    receiveShadow
                    geometry={nodes.Shape_33.geometry}
                    material={eyeBrowMaterial()}
                    position={[-54.633, 58.118, 54.241]}
                    rotation={[0, -0.422, 0.094]}
                />
                <mesh
                    name="Sphere_6_33"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_6_33.geometry}
                    ref={(el) => (eyeRefs.current[32] = el)}

                    material={eyeMaterial()}
                    position={[-38.215, 21.412, 74.542]}
                    rotation={[-0.185, -0.344, -0.063]}
                    scale={[1.15, 1.15, 0.324]}
                />
                <mesh
                    name="Sphere_5_16"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_5_16.geometry}
                    material={eyeWhiteMaterial()}
                    position={[-40.373, 21.412, 67.241]}
                    rotation={[-0.185, -0.344, -0.063]}
                    scale={[1.65, 1.65, 0.466]}
                />
                <mesh
                    name="Sphere_6_34"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_6_34.geometry}
                    ref={(el) => (eyeRefs.current[33] = el)}
                    material={eyeMaterial()}
                    position={[38.146, 21.4, 74.431]}
                    rotation={[-0.168, 0.344, 0.063]}
                    scale={[1.15, 1.15, 0.324]}
                />
                <mesh
                    name="Sphere_3_15"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_3_15.geometry}
                    material={eyeWhiteMaterial()}
                    position={[40, 21.412, 67.241]}
                    rotation={[-0.168, 0.344, 0.063]}
                    scale={[1.65, 1.65, 0.466]}
                />
                <mesh
                    name="Cylinder_2_17"
                    castShadow
                    receiveShadow
                    geometry={nodes.Cylinder_2_17.geometry}
                    material={
                        createHeadMaterial(headColor8)
                    }
                    position={[-0.433, 4.206, -5.505]}
                    rotation={[0, 0, Math.PI / 2]}
                    scale={[0.969, 0.924, 0.557]}
                />
                <mesh
                    name="Cylinder_17"
                    castShadow
                    receiveShadow
                    geometry={nodes.Cylinder_17.geometry}
                    material={
                        createHeadMaterial(headColor8)
                    }
                    position={[0, 12.463, 64.967]}
                    rotation={[2.792, 0, 0]}
                    scale={[0.969, 1.147, 1.147]}
                />
                <mesh
                    name="Sphere_19"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_19.geometry}
                    material={
                        createHeadMaterial(headColor8)
                    }
                    position={[0, 0, -12.338]}
                />
            </group>

            <group name="Group_4_Instance_1" position={[258.398, -322.414, 172.791]} ref={(el) => (headRefs.current[19] = el)}>
                <mesh
                    name="Shape_34"
                    castShadow
                    receiveShadow
                    geometry={nodes.Shape_34.geometry}
                    material={eyeBrowMaterial()}
                    position={[24.087, 60.502, 65.424]}
                    rotation={[Math.PI, -0.553, -3.072]}
                    scale={[-1, 1, 1]}
                />
                <mesh
                    name="Shape_35"
                    castShadow
                    receiveShadow
                    geometry={nodes.Shape_35.geometry}
                    material={eyeBrowMaterial()}
                    position={[-54.633, 58.118, 54.241]}
                    rotation={[0, -0.422, 0.094]}
                />
                <mesh
                    name="Sphere_6_35"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_6_35.geometry}
                    ref={(el) => (eyeRefs.current[38] = el)}
                    material={eyeMaterial()}
                    position={[-38.215, 21.412, 74.542]}
                    rotation={[-0.185, -0.344, -0.063]}
                    scale={[1.15, 1.15, 0.324]}
                />
                <mesh
                    name="Sphere_5_17"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_5_17.geometry}
                    material={eyeWhiteMaterial()}
                    position={[-40.373, 21.412, 67.241]}
                    rotation={[-0.185, -0.344, -0.063]}
                    scale={[1.65, 1.65, 0.466]}
                />
                <mesh
                    name="Sphere_6_36"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_6_36.geometry}
                    ref={(el) => (eyeRefs.current[39] = el)}
                    material={eyeMaterial()}
                    position={[38.146, 21.4, 74.431]}
                    rotation={[-0.168, 0.344, 0.063]}
                    scale={[1.15, 1.15, 0.324]}
                />
                <mesh
                    name="Sphere_3_16"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_3_16.geometry}
                    material={eyeWhiteMaterial()}
                    position={[40, 21.412, 67.241]}
                    rotation={[-0.168, 0.344, 0.063]}
                    scale={[1.65, 1.65, 0.466]}
                />
                <mesh
                    name="Cylinder_2_18"
                    castShadow
                    receiveShadow
                    geometry={nodes.Cylinder_2_18.geometry}
                    material={
                        createHeadMaterial(headColor1)
                    }
                    position={[-0.433, 4.206, -5.505]}
                    rotation={[0, 0, Math.PI / 2]}
                    scale={[0.969, 0.924, 0.557]}
                />
                <mesh
                    name="Cylinder_18"
                    castShadow
                    receiveShadow
                    geometry={nodes.Cylinder_18.geometry}
                    material={
                        createHeadMaterial(headColor1)
                    }
                    position={[0, 12.463, 64.967]}
                    rotation={[2.792, 0, 0]}
                    scale={[0.969, 1.147, 1.147]}
                />
                <mesh
                    name="Sphere_20"
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere_20.geometry}

                    material={
                        createHeadMaterial(headColor1)
                    }
                    position={[0, 0, -12.338]}
                />
            </group>

        </group>
    )

}

function Bodies({ nodes }) {
    const metalBlackMaterial = useMemo(() => {
        return new THREE.MeshStandardMaterial({
            color: '#252525',
            metalness: 0.1,
            roughness: 0.6,
            side: THREE.DoubleSide,
            castShadow: true,
            receiveShadow: true,

        });
    }, []);

    const bodyMeshes = [
        { name: 'Cube_17', position: [587.581, 58.249, 35.788], scale: [0.847, 1, 0.603] },
        { name: 'Cube_16', position: [770.022, -202.567, 127.382], rotation: [0, -0.264, 0], scale: [0.713, 1.836, 0.603] },
        { name: 'Cube_18', position: [467.013, -220.787, 105.991], scale: [0.713, 1.892, 0.603] },
        { name: 'Cube_8', position: [540.406, -251.32, 187.485], rotation: [0, -0.294, 0], scale: [0.713, 1.656, 0.603] },
        { name: 'Cube_9', position: [-260.634, -157.13, 188.526], rotation: [0, 0.387, 0], scale: [0.713, 1.546, 0.603] },
        { name: 'Cube_10', position: [301.336, 165.456, 142.645], rotation: [0.163, 0, 0], scale: [0.713, 1, 0.603] },
        { name: 'Cube_7', position: [268.76, -112.578, 218.205], scale: [0.807, 1, 0.603] },
        { name: 'Cube_19', position: [-481.879, -244.152, 272.524], rotation: [0, 0.267, 0], scale: [0.695, 1.617, 0.603] },
        { name: 'Cube_15', position: [-702.363, -180.705, 206.637], rotation: [0, 0.446, 0], scale: [0.695, 1.615, 0.603] },
        { name: 'Cube_14', position: [-619.36, 76.075, 20.163], rotation: [0, 0.134, 0], scale: [0.695, 1, 0.603] },
        { name: 'Cube_24', position: [219.723, 337.218, -46.595], scale: [0.695, 1, 0.603] },
        { name: 'Cube_23', position: [404.647, 300.589, -78.599], scale: [0.695, 1, 0.603] },
        { name: 'Cube_21', position: [189.949, 381.049, -221.855], scale: [0.695, 1, 0.603] },
        { name: 'Cube_22', position: [-408.57, 232.333, -11.675], scale: [0.695, 1, 0.603] },
        { name: 'Cube_20', position: [-178.9, 410.51, -132.015], rotation: [0.201, 0, 0], scale: [0.695, 1, 0.603] },
        { name: 'Cube_13', position: [-391.324, 28.182, 142.449], scale: [0.695, 1.614, 0.603] },
        { name: 'Cube_12', position: [-60.701, 355.496, 22.255], rotation: [0.077, 0, 0], scale: [0.855, 1, 0.603] },
        { name: 'Cube_11', position: [-166.418, 65.981, 121.795], rotation: [0.146, 0, 0], scale: [0.695, 1, 0.603] },
        { name: 'Cube_6', position: [58.918, 128.453, 161.976], scale: [1, 1, 0.603] },
        { name: 'Cube_5', position: [-6.624, -49.547, 311.173], scale: [1, 1, 0.474] },
    ];

    return (
        <group name="Bodies" position={[279.124, -46.526, -691.985]}>
            {bodyMeshes.map(({ name, position, rotation = [0, 0, 0], scale }) => (
                <mesh
                    key={name}
                    name={name}
                    castShadow
                    receiveShadow
                    geometry={nodes[name].geometry}
                    material={metalBlackMaterial}
                    position={position}
                    rotation={rotation}
                    scale={scale}
                />
            ))}
        </group>
    );
}




export function Model(props) {
    const { nodes, materials } = useGLTF('100_followers.gltf')
    return (
        <group {...props} dispose={null}>
            <group>
                <group scale={0.01}>
                    <group name="Scene_1">
                        <mesh
                            name="imagepng"
                            castShadow
                            receiveShadow
                            geometry={nodes.imagepng.geometry}
                            material={nodes.imagepng.material}
                            position={[276.986, -172.888, -163.183]}
                            rotation={[-1.573, 0, 0]}
                        />
                        <ambientLight intensity={0.4} />

                        <pointLight
                            name="Point_Light"
                            intensity={2.92}
                            decay={2}
                            distance={4642}
                            color="#EE71FF"
                            position={[391.158, 2965.87, -3668]}
                        />
                        <group name="Laptop" position={[272.5, -105.094, -123.235]}>
                            <mesh
                                name="Laptop_Logo"
                                castShadow
                                receiveShadow
                                geometry={nodes.Laptop_Logo.geometry}
                                material={nodes.Laptop_Logo.material}
                                position={[0, 0, 131.328]}
                                rotation={[0.436, 0, 0]}
                                scale={0.7}
                            />
                            <pointLight
                                name="Laptop_light"
                                intensity={20.287}
                                decay={2}
                                distance={2179}
                                color="#0044ff"
                                position={[0.242, 1.083, -27.934]}
                            />
                            <mesh
                                name="Cube_3"
                                castShadow
                                receiveShadow
                                geometry={nodes.Cube_3.geometry}
                                material={nodes.Cube_3.material}
                                position={[0, 0, 114.122]}
                                rotation={[2.007, 0, 0]}
                            />
                            <mesh
                                name="Cube_2"
                                castShadow
                                receiveShadow
                                geometry={nodes.Cube_2.geometry}
                                material={nodes.Cube_2.material}
                                position={[0, -77.704, -56.836]}
                            />
                        </group>
                        <OrthographicCamera
                            name="Camera"
                            makeDefault={false}
                            far={100000}
                            near={0}
                            position={[271.193, 491.11, 842.116]}
                            rotation={[-0.14, 0, 0]}
                        />
                        {/* <mesh
                            name="Background_Circle"
                            castShadow
                            receiveShadow
                            geometry={nodes.Background_Circle.geometry}
                            material={nodes.Background_Circle.material}
                            position={[299, -300.935, -1202.79]}
                            scale={1.62}
                        /> */}

                        <Background nodes={nodes} />
                        <directionalLight
                            name="Filling_light"
                            intensity={0.518}
                            decay={2}
                            rotation={[-0.374, 0.286, 0.948]}>
                            <group position={[0, 0, -1]} />
                        </directionalLight>
                        <pointLight
                            name="Left_light"
                            intensity={0.516}
                            decay={2}
                            color="#EE71FF"
                            distance={10000}
                            position={[-1095.01, 982.872, -678.484]}
                        />

                        <Bodies nodes={nodes} />

                        <Heads nodes={nodes} />

                        <group name="Focus_point" position={[162.77, -126.684, 123]} />
                        <mesh
                            name="Table"

                            geometry={nodes.Table.geometry}
                            material={
                                new THREE.MeshStandardMaterial({
                                    color: 0x000000, // Hex representation of RGB(252, 255, 252)
                                    castShadow: true,
                                    receiveShadow: true,
                                    metalness: 0.6,
                                    roughness: 1
                                })
                            }
                            position={[272.5, -202.607, 7827.399]}
                            scale={[0.72, 1, 1]}
                        />

                        <OrthographicCamera
                            makeDefault={false}
                            far={100000}
                            near={0}
                            position={[-348.327, 514.249, 1046.61]}
                            rotation={[-0.264, -0.024, -0.006]}
                        />
                        <group name="Default_Ambient_Light" position={[0, 1, 0]} />
                    </group>
                </group>
            </group>
        </group>
    )
}

useGLTF.preload('/100_followers.gltf')
