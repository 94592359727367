'use client'
import Home from "@/components/public/main-page/Home"
import LandingPage from "@/components/public/main-page/LandingPage"
import { Button } from "@/components/ui/button"
import { Card } from "@/components/ui/card"
import { Bitcoin, ChevronRight, Wallet } from "lucide-react"
import Link from "next/link"
import { useRouter } from 'next/navigation'


// const links = [
//     {
//         title: "Boosted",
//         href: "/boosted",
//     },
//     {
//         title: "Dex Screener Live Api",
//         href: "/dex-screener-boost",
//     },
//     {
//         title: "Articles",
//         href: "/articles",
//     }
// ]
export default function Main() {
    const router = useRouter()

    return (
        <div className="">
            <Home />
            <LandingPage />
        </div>
    )
}

