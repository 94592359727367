import { useState, useEffect } from 'react'

export const useMousePosition = () => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 })
  const [isStopped, setIsStopped] = useState(false)
  let timer

  useEffect(() => {
    const updateMousePosition = (ev) => {
      setMousePosition({ x: ev.clientX, y: ev.clientY })
      setIsStopped(false)
      clearTimeout(timer)
      timer = setTimeout(() => setIsStopped(true), 1000) // Consider mouse stopped after 1 second of inactivity
    }

    window.addEventListener('mousemove', updateMousePosition)

    return () => {
      window.removeEventListener('mousemove', updateMousePosition)
      clearTimeout(timer)
    }
  }, [])

  return { mousePosition, isStopped }
}
