'use client'
import React, { Suspense, useRef } from 'react'
import { Canvas } from '@react-three/fiber'
import { Environment, OrbitControls, PerspectiveCamera, SpotLight } from '@react-three/drei'
import { Model } from './Model'
import { Bloom, EffectComposer, N8AO, Outline, Selection, TiltShift2, ToneMapping } from '@react-three/postprocessing'

export default function Home() {
    const spotLightRef = useRef()

    return (
        <div style={{ width: '100vw', height: '100vh', position: 'fixed', top: 0, left: 0 }}>
            <Canvas
                shadows
            >
                <PerspectiveCamera makeDefault position={[2.7, 2, 7]} fov={70} near={0.2} far={1800} />
                <ambientLight intensity={0.5} />
                <directionalLight position={[10, 10, 5]} intensity={1} />
                <Suspense fallback={null}>
                        <Model />
                        {/* <Environment preset="" background blur={0.6} /> */}
                </Suspense>

                {/* <OrbitControls
                    enableZoom
                    enablePan
                    enableRotate
                    minPolarAngle={Math.PI / 2}
                    maxPolarAngle={Math.PI / 2}
                    minDistance={3}
                    maxDistance={10}
                    target={[0, 1, 0]}
                /> */}
            </Canvas>
        </div>
    )
}


